.contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/1.jpg');
  background-size: cover;
  opacity: 0.3; /* Adjust opacity as desired */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; /* Adjust overlay opacity as desired */
}

.content {
  position: absolute;
  bottom: 80px; /* Position at the bottom */
  left: 20px;; /* Add padding for spacing */
}
.content {
  font-weight: bold;
  font: 900 2rem Roboto;
  color: black;
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 40px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}
.navigation {
  font: 300 1rem Roboto;
  color: white;
  position: absolute;
  padding: 0;
  margin: 0;

  bottom: 20px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}


.navigation span {
  margin-right: 5px; /* Add some spacing between HOME and CONTACT */
}
.contact{
  color: rgb(37, 150, 190);
  border: none;
}



.office {
  margin-top: 20px;
}

.office h3 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.office p {
  margin: 5px 0;
  font-size: 0.9rem;
}

