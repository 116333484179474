.data-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
}
.top-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.data-text {
  padding: 10px 0;
  width: 50%;
  font: 100 1rem Roboto;
  color: rgb(37, 150, 190);
  text-align: center;
  position: relative;
}

.data-text::before,
.data-text::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(185, 182, 182, 0.1); /* Shadow color */
}

.data-text::before {
  top: 0;
}

.data-text::after {
  bottom: 0;
}


.bottom-row {
  opacity: 0.2; /* Adjust the opacity as needed */
}

.data-image {
  width: 100%;
  height: auto;
}

.flex-container1 {
  opacity: 1;
  display: flex;
  padding: 5px 100px;
 
  height: 50vh;
  background-color: rgb(37, 150, 190);
}



.column {
  flex: 1;
  padding: 10px;
  font: 300 1rem Roboto;
  color: rgb(255, 255, 255);
  
  
}
.column > img{
  width: 6rem; /* Adjust width as needed */
  height: 6rem; /* Adjust height as needed */
  padding: 0 100px;
}
.contact1 {
  color: white;
  font: 300 1rem Roboto;
}
.contact {
  border-bottom: 1px solid black;
}

.client-images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px; /* Adjust as needed */
}

.client-images img {
  width: 60%; /* Ensure images fill their container */
}

.careers ul {
  list-style-type: none;
  padding-left: 0;
}
.link-style {
  display: block;
  margin: 0;
  padding: 0;
  color: white;
  text-decoration: none;

  /* Add any additional styles here to match the appearance of a <p> element */
}
