/* .contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/1.jpg');
  background-size: cover;
  opacity: 0.3;  
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
}

.content {
  position: absolute;
  bottom: 80px; 
  left: 20px;; 
}
.content {
  font-weight: bold;
  font: 900 2rem Roboto;
  color: black;
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 40px; 
  left: 20px; 
}
.navigation {
  font: 300 1rem Roboto;
  color: white;
  position: absolute;
  padding: 0;
  margin: 0;

  bottom: 20px; 
  left: 20px; 
}


.navigation span {
  margin-right: 5px;
}
.contact{
  color: rgb(37, 150, 190);
  border: none;
}



.products{
  margin-top: 20px;
}


 */


 .contact-container {
  position: relative;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/1.jpg');
  background-size: cover;
  opacity: 0.3; /* Adjust opacity as desired */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5; /* Adjust overlay opacity as desired */
}

.content {
  font-weight: bold;
  font: 900 2rem Roboto;
  color: black;
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 40px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}

.navigation {
  font: 300 1rem Roboto;
  color: white;
  position: absolute;
  padding: 0;
  margin: 0;
  bottom: 20px; /* Position at the bottom */
  left: 20px; /* Position at the left */
}

.navigation span {
  margin-right: 5px; /* Add some spacing between HOME and CONTACT */
}

.contact {
  color: rgb(37, 150, 190);
  border: none;
}

.products-info {
  margin-top: 20px;
  background-color: white;
}

.tabs-container {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}


.tab {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tab.active {
  background-color: #ddd;
}

.tab-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items from left to right */
}

.tab-content.active {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* Align items from left to right */
}

.product {
  text-align: center;
  margin: 10px;
  width: calc(25% - 20px); /* Set width to 25% minus margin for four columns */
  opacity: 0; /* Initially set opacity to 0 for fade-in effect */
  animation: fadeIn 1s ease forwards; /* Apply fade-in animation */
}

.product img {
  width: 100%;
  height: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.product p {
  margin-top: 10px;
}

