/* 
.custom-carousel {
  width: 100%;

}

.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50vh;
}

.carousel-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}


.container {
  padding: 10px 200px ;
  display: flex;
  justify-content: space-between;
  
}

.heading-container {
  text-align: center;
}

.heading {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  padding-top: 10px;
}

.sub-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: green;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.box {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
 
}

.image {
  width: calc(100% - 20px);
  padding: 10px;
  height: auto;
  object-fit: cover;
  
  margin-bottom: 10px;
}

.box-content {
  padding: 10px;
}

.heading-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: green;
}

.description {
  font-family: 'Roboto', sans-serif;
}

.link {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  border: 2px solid black;
  background-color: white;
  margin-top: 10px;
} */

/* Home.css */

/* Carousel styles */
.custom-carousel {
  width: 100%;
}

.carousel-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
}

.carousel-image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

/* Container styles */
.container {
  padding: 10px 200px;
  display: flex;
  justify-content: space-between;
}

.heading-container {
  text-align: center;
}

.heading {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  padding-top: 10px;
}

.sub-heading {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: green;
  font-weight: bold;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

/* Box styles */
.box {
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
}

.image {
  width: calc(100% - 20px);
  padding: 10px;
  height: auto;
  object-fit: cover;
  margin-bottom: 10px;
  animation: zoomIn 1s ease;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.box-content {
  padding: 10px;
  animation: zoomIn 1s ease;
}

.heading-text {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: green;
}

.description {
  font-family: 'Roboto', sans-serif;
}

.link {
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  border: 2px solid black;
  background-color: white;
  margin-top: 10px;
}

/* Animation styles */
.box.show {
  opacity: 1;
}
.data-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  align-items: center;
}
.top-row {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.bottom-row {
  opacity: 0.2; /* Adjust the opacity as needed */
}
