.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
}

.footer-content {
  display: flex;
  flex: 3;
}

.footer-text {
  flex: 12;
  text-align: center;
  color: rgb(37, 150, 190);
}

.footer-icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.icon {
  margin-left: 10px;
  font-size: 1.5rem;
  color: rgb(37, 150, 190);
}
