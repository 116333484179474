body {
  background-color: rgba(226, 226, 228, 255);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

.heading1 {
  color: rgb(37, 150, 190);
  font: 200 1rem Roboto;
  background: transparent;
  border: none;
  margin-bottom: 0px;
  margin-left: 10px;
}

/* Styles for the navigation */
nav {
  background: transparent;
  box-shadow: none;
  margin-bottom: 0px;
  padding: 1rem;
}

nav > a {
  font: 300 1rem Roboto;
  margin-bottom: 0px;
  color: rgb(37, 150, 190);
  text-decoration: none;
  margin: 0 1rem;
  position: relative; /* Ensure relative positioning for dropdown positioning */
}

/* Dropdown Menu */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: rgba(226,226,228,255);
  width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 100%; /* Position below the parent link */
  left: 0; /* Align with the parent link */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu when hovering over the parent link */
nav > a:hover .dropdown-content {
  display: block;
}
