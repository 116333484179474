/* Header.css

.header-container {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.header-container nav {
  padding: 1rem;
}

.header-container nav > a {
  font: 300 1rem Roboto;
  color: green;
  text-decoration: none;
  margin: 0 1rem;
}

.header-container nav:first-child > a img {
  width: 4rem; /* Adjust width as needed 
  height: 4rem; /* Adjust height as needed 
} */

/* Header.css */

.header-container {
  display: flex;
  justify-content: space-between;
  padding: 0;
  background-color: rgba(226,226,228,255);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  transition: all 1s ease, top 1s ease;
  /*transition: all 0.3s ease;*/ /* Smooth transition for animation */
}

.header-container.sticky {
  position: fixed;
  top: 0;
  padding: 0;
  width: 100%;
  background-color: rgba(226,226,228,255); /* Change background color when sticky if needed */
  z-index: 1000;
  transition: all 1s ease;
}

.header-container nav {
  padding: none;
}

.header-container nav > a {
  font: 300 1rem Roboto;
  color: rgb(37, 150, 190);
  text-decoration: none;
  margin: 0 1rem;
}

.header-container nav:first-child > a img {
  width: 8rem; /* Adjust width as needed */
  height: 4rem; /* Adjust height as needed */
  padding: 0;
}

/* Add more styles as needed */


